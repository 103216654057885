.footerContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.optionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
  margin-top: 54px;
}
.socialContainer {
  display: flex;
  gap: 16px;
  margin-top: 36px;
  flex-wrap: wrap;
  justify-content: center;
}
.socialName {
  font-size: 14px;
  font-weight: 600;
  line-height: 24.5px;
}
.socialImg {
  width: 41px;
  height: 41px;
}
.footer_separator {
  width: 959px;
  max-width: 100%;
  height: 1px;
  background-color: #c2c2c2;
  margin-top: 38px;
}
.footer_copyRight {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24.5px;
  margin-bottom: 15px;
}
.footer__projectName {
  text-transform: uppercase;
  margin-right: 4px;
  text-align: center;
}

@media (max-width: 61.9375em) {
  .optionsContainer {
    column-gap: 50px;
    row-gap: 14px;
  }

  .footer_copyRight {
    font-size: 10px;
    margin-top: 6px;
    margin-bottom: 9px;
  }

  .socialName {
    font-size: 13px;
  }

  .socialContainer {
    margin-top: 44px;
    gap: 14px;
  }

  .footer_separator {
    margin-top: 36px;
  }

  .socialImg {
    width: 38px;
    height: 38px;
  }
}
