.violation__boardContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #c2c2c2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--secondary-color);
  padding: 15px 24px 36px 24px;
  min-height: 212px;
}

.violations__list {
  margin-top: 12px;
  display: flex;
  gap: 30px;
  overflow: auto;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .violation__boardContainer {
    padding: 15px 16px 21px 16px;
  }

  .violations__list {
    gap: 34px;
  }
}
