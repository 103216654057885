.documentCard {
  display: flex;
  flex-direction: column;
  gap: 22px;

  width: var(--document-card-width);
  padding: 21px 21px 14px 21px;
  border-radius: 4px;
  box-shadow: 0px 4px 11px 0px #0000001a;
  background: var(--background-card);
  border: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.08) 100%
  );
}

.documentCard__info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.documentCard__icon {
  min-width: 20px;
  min-height: 20px;
  max-width: 44px;
  max-height: 44px;
}

.documentCard__name {
  flex: 1;
  font-weight: 500;
  line-height: 22px;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .documentCard {
    width: calc((100% - 15px) / 2);
  }
}

/* Mobile: width <= 767px */
@media (max-width: 47.9375em) {
  .documentCard {
    width: 100%;
    padding: 21px;
  }

  .documentCard__info {
    min-height: 34px;
  }
}
