.cardDetail {
  width: 25%;
  width: 280px;
  max-width: 100%;
  padding: 12px 12px 25px 12px;
  max-height: 80vh;
  overflow-y: auto;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  box-shadow: 0px 4px 11px 0px #00000040;
  color: var(--main-color) !important;
}

.cardDetail__avatar {
  max-width: 100%;
}

.nameAndRole {
  margin-top: 2px;
  padding: 7px;
  background-color: var(--main-color);
  color: var(--secondary-color);
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 3px;
  text-align: center;
}

.cardDetail__content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 56px;
}

.cardItem {
  display: flex;
  gap: 14px;
  text-align: left;
}

.cardItemTitle {
  width: 143px;
  font-weight: 600;
  line-height: 22px;
}

.cardItemValue {
  flex: 1;
}

/* Card new UI */
.cardWrap {
  padding: 2px;
  position: relative;
  width: 100%;
  height: 100%;
}

.cardContent {
  height: 100%;
  background-color: #fff;
  margin-left: -1px;
  border-radius: 4px;
  border: 1px solid #e4e2e9;
  padding: 10px 20px;
  box-shadow: 0px 4px 11px #45483350;

  display: flex;
  align-items: center;
  justify-content: center;
}

.cardContent__name {
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;

  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

/* tippy */
.tippy-box[data-theme~="cardDetailToollip"][data-placement^="left"]
  > .tippy-arrow {
  right: 10px;
}

.tippy-box[data-theme~="cardDetailToollip"][data-placement^="right"]
  > .tippy-arrow {
  left: 10px;
}

.tippy-box[data-theme~="cardDetailToollip"][data-placement^="left"]
  > .tippy-arrow::before {
  border-width: 14px 0 14px 14px !important;
  right: -13px;
}

.tippy-box[data-theme~="cardDetailToollip"][data-placement^="right"]
  > .tippy-arrow::before {
  border-width: 14px 14px 14px 0 !important;
  left: -13px;
}

.tippy-box[data-theme~="cardDetailToollip"] .tippy-arrow {
  color: rgba(69, 72, 51, 0.24);
  box-shadow: 0px 4px 11px 1px #00000040;
}

/* override chart */
.org-chart .node:focus {
  outline: none;
}
