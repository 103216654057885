.newsElement {
  flex-shrink: 0;
  width: 158px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.newsElement__image {
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  filter: brightness(1) saturate(0%) invert(21%) sepia(38%) saturate(105%)
    hue-rotate(29deg) brightness(85%) contrast(87%);
}

.newsElement:hover > .newsElement__image {
  filter: none;
}

.newsElement__name {
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
  margin-top: 16px;
}

.newsElement__description {
  margin-top: 8px;
}

.newsElement--violations.newsElement {
  width: 215px;
}

.newsElement--violations.newsElement .newsElement__image {
  width: 158px;
}

/* Mobile and Tablet */
@media (max-width: 61.9375em) {
  .newsElement--violations.newsElement {
    width: 158px;
  }

  .newsElement__name {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }
  .newsElement__description {
    font-size: 13px;
    font-style: normal;
    line-height: 22px;
  }
}
